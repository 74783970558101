import React, {useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList} from '@fortawesome/free-solid-svg-icons'
import { RoomDialog } from './Dialogs'



type RoomSelectionTextBoxProps = {
   readonly text: string;
   readonly phraseKey:string;
   readonly defaultRows?: number;
   readonly lineHeight?: number;
   readonly class?:string;
   readonly id?:string;
   readonly focus?:boolean;
   readonly readOnly?:boolean;
   readonly addPadding?:boolean;
   readonly alphabetical?:boolean;
   readonly onValueChangeCb?: (value: string) => void;
 };

const keyPressTimer = 1000;
let inputTimer = null;

function  RoomSelectionTextBox(props: RoomSelectionTextBoxProps) {

   const [rows, setRows] = useState(1);
   const [text, setText] = useState('');
   const [selectedText, setSelectedText] = useState('');
   const [lineHeight, setLineHeight] = useState(16);
   const [openRoomsDialog, setOpenRoomsDialog] = useState(false);
   const textBoxRef = useRef(null);
   const [phraseKey, setPhraseKey] = useState('');
   const [id, setId] = useState('auto');
   const [alphabetical, setAlphabetical] = useState(false);
   const [readOnly, setReadOnly] = useState(false);

   var init = 0;

   useEffect(() => {
      if (props.defaultRows != null) {
         setRows(props.defaultRows);
      }
      if (props.lineHeight != null) {
         setLineHeight(props.lineHeight);
      }
      setText(props.text)
      if (props.id) {
         setId(props.id);
      }
      if (props.phraseKey) {
         setPhraseKey(props.phraseKey);
      }
      if (props.readOnly != null){
         setReadOnly(props.readOnly);
      }
      if (props.alphabetical) {
         setAlphabetical(props.alphabetical);
      }
   }, [props]);


   useEffect(() => {
      if (!init) {
         const previousRows = textBoxRef.current.rows;
         textBoxRef.current.rows = props.defaultRows;

         const currentRows = ~~(textBoxRef.current.scrollHeight / lineHeight);
         if (currentRows === previousRows) {
            textBoxRef.current.rows = currentRows;
         }
         setRows(currentRows);
         init=1;
      }
      if (props.focus) {
         textBoxRef.current.focus();
      }
   });

   const onBlur = () => {
      if (inputTimer !== null){
         clearTimeout(inputTimer);
         props.onValueChangeCb(text);
      }
   }

   const onValueChange = (e) => {
      const minRows = props.defaultRows;

      const { name, value } = e.target;
      setText(value);

      const previousRows = e.target.rows;
  	   e.target.rows = minRows; // reset number of rows in textarea 
		
		const currentRows = ~~(e.target.scrollHeight / lineHeight);

      if (currentRows === previousRows) {
         e.target.rows = currentRows;
      }
		
		setRows(currentRows);
      
      if (inputTimer !== null){
         clearTimeout(inputTimer);
       }
       inputTimer = setTimeout(() => {
         updateValue(value);
       }, keyPressTimer);  
   }

   const updateValue = (value) => {
      props.onValueChangeCb(value);
   }

   const openRoomsRegister = () => {
      let cursorStart = textBoxRef.current.selectionStart;
      let cursorEnd = textBoxRef.current.selectionEnd;
      if (cursorEnd > cursorStart) {
         setSelectedText(text.substring(cursorStart,cursorEnd));
      }
      else {
         setSelectedText(text);
      }
      setOpenRoomsDialog(true);
   }

   const handleRoomsSave = (value) => {
      setOpenRoomsDialog(false);
      if (value == '') {
         return;
      }
      if (text== null || text==undefined || text=='') {
         setText(value);
         props.onValueChangeCb(value);
      }
      else {
         setText(text + value);
         props.onValueChangeCb(text +  ', '  + value);
      }
   }
   
   const handleRoomsCancel = () => {
      setOpenRoomsDialog(false);
   }

 
   return <>
      <div className="auto-text-box-frame" key={id}>
         <div className="text-box">
            <textarea ref={textBoxRef} readOnly={readOnly} className={"form-control " + props.class} rows={rows} value={text} onChange={(e) => onValueChange(e)} onBlur={onBlur}/>
         </div>
         {(props.addPadding) && 
            <>
            <div className="phrase-icon" ></div>
            </>
         }
            <>
            <div className="add-icon"> 
            <FontAwesomeIcon icon={faClipboardList}  onClick={openRoomsRegister}/>
            </div>
            </>
      </div>

      <RoomDialog
         open={openRoomsDialog}
         phraseKey={'area-table-rooms-' + phraseKey}
         text={selectedText}
         handleSaveCb = {handleRoomsSave}
         handleCancelCb = {handleRoomsCancel}
         alphabetical = {alphabetical}
      />
     
   </>
}
  
export default RoomSelectionTextBox;