import React, { useState, useEffect } from 'react';
import { actionCreators } from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory, useParams } from 'react-router-dom'
import { AdminApi } from '../../api/admin.service';
import AdminMenu from './child-components/AdminMenu'
import CustomerRow from './child-components/CustomerRow'
import {Spinner} from '../../components'

type CustomersProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly isAdmin: boolean;
};

const keyPressTimer = 1000;
let inputTimer = null;
const defaultTake = 50;

const Customers: React.FC<CustomersProps> = ({
   isAuthenticated,
   isAdmin,
   logoutUser
}) => {
   const [isLoading, _setIsLoading] = useState(true);
   const [isMount, setIsMount] = useState(true);
   const [customers, setCustomers] = useState([]);
   const [customerTypes, setCustomersTypes] = useState([]);
   const [totalCount, setTotalCount] = useState(0);
   const [adminssearch, setAdminsearch] = useState(false)

   const [searchModel, setSearchModel] = useState({
      take: 10,
      skip: 0,
      sortBy: 'LastLogin',
      searchString: '',
      ascending: false,
      filterByDeactivated:false,
      filterBySubscribers:false,
      filterByNonPayers:false, 
      filterByFreeUsers: false,
      userType:0
   })

   const [filterid, setFilterId] = useState(0)


   const history = useHistory();
   const params = useParams();
   const filter = (params as any).filter;

   const isLoadingRef = React.useRef(isLoading);
   const setIsLoading = data => {
      isLoadingRef.current = data;
     _setIsLoading(data);
   };

   useEffect(() => {
      setSearchModel(searchModel => ({ ...searchModel, take: defaultTake }));
   }, []);


   useEffect(() => {
      // First to skip the initialising phase. 
      // because of security reasons we have to check user role before fetching
      if (isMount) {
         setIsMount(false);
         return;
      }
      // Fetch data based on the changes in the search model
      fetchData();
   }, [searchModel])

   const fetchData = async () => {
      setIsLoading(true);
      AdminApi.getCustomers(searchModel)
         .then(
            (response) => {
               if (searchModel.skip > 0) {
                  setCustomers([...customers, ...response.data.customers]);
               }
               else {
                  setCustomers(response.data.customers);
                  setTotalCount(response.data.totalCount)
               }
               setIsLoading(false);
            },
            (error) => {
               console.log(error);
               setIsLoading(false);
            });
   }


   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if (!isAdmin) {
      history.push("/projects");
   }


   const handleScroll = function (e) {
      const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
      //console.log(e.target.scrollHeight + ", " + e.target.scrollTop + ", " + e.target.clientHeight);
      if (bottom && !isLoading) {
         var skip = searchModel.skip + searchModel.take;
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: skip }));

      }

   }

   const doTextSearch = function (searchText) {
      setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, searchString: searchText }));
   }

   const onSearchTextChanged = function (e) {
      const { value } = e.target;

      if (inputTimer !== null) {
         clearTimeout(inputTimer);
      }

      inputTimer = setTimeout(() => {
         doTextSearch(value);
      }, keyPressTimer);
   }

   const onSelectChanged = (e) => {
      const { value } = e.target;

      if (value==0) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, userType:0 }));
      }
      else if (value==1) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, filterBySubscribers:true, userType:0 }));        
      }
      else if (value==2) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, filterByDeactivated: true, userType:0 }));        
      }
      else if (value==3) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, filterByDeactivated: true, filterBySubscribers:true, userType:0 }));        
      }
      else if (value==4) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, filterByNonPayers:true, userType:0 }));        
      }
      else if (value==5) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, filterByFreeUsers: true, userType:0 }));        
      }
      else if (value==6) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, userType:4 }));               
      }
      else if (value==7) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, userType:2 }));        
      }
   }
   const [userStatistics, setUserStatistics] = useState({
      /*acceptedUsers: {},
      projects: {},
      services: {},*/
      otherCalculations: [],
      calculations: [],
      reports: []
   });


   const fetchstatData = async (id) => {
      AdminApi.getUserStatistics(id)
         .then(
            (response) => {
               setUserStatistics(response.data);
            },
            (error) => {
               console.log(error);
            });

      return userStatistics
   }

   let customerRows = null;
   if (customers && customers.length > 0) {
      customerRows = customers.map((customer, index) => {
         return (
            <CustomerRow customer={customer} index={index}  ></CustomerRow>
         )
      });
   }


   return (
      <>
         <div className="admin-container">

            <AdminMenu activeMenuItem={'Kunder'} activeSubIndex={filter}></AdminMenu>

            <div className=''>
                  <div className='search-container my-2 mx-3'>
                     <div className="">
                        <input type="text" className='form-control' placeholder="Søk ..." onChange={(e) => onSearchTextChanged(e)}></input>
                     </div>
                     <div className="">
                        <select className='form-control' onChange={onSelectChanged}>
                           <option key={0} value={0}>Alle kunder</option>
                           <option key={1} value={1}>Abonnenter</option>
                           <option key={2} value={2}>Deaktiverte</option>
                           <option key={3} value={3}>Abonnenter - Deaktivert</option>
                           <option key={4} value={4}>Ikke abonnenter</option>
                           <option key={5} value={5}>Gratis til</option>
                           <option key={6} value={6}>Studenter</option>
                           <option key={7} value={7}>Admin</option>      
                        </select>
                     </div>
                     <div className='admin-count'>(Antall: {totalCount})</div>
                  </div>

               <div className="customer-table-header mx-3">
                  <div className="">Nr</div>
                  <div className="">Status</div>
                  <div className="">Kundenr</div>
                  <div className="">Etternavn</div>
                  <div className="">Fornavn</div>
                  <div className="">Firmanavn</div>
                  <div className="">Registrert</div>
                  <div className="">Siste login</div>
                  <div className="align-right">Prosj.</div>
                  <div className="align-right">Kalk.</div>
                  <div className="align-right">Rapp.</div>
               </div>

               <div className="customer-table-content mx-3" onScroll={handleScroll}>
                  {customerRows}
               </div>


            </div>
            <Spinner isLoading={isLoading} />
         </div>
      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, actionCreators)(Customers);