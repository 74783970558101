import React, {useState, useEffect, useRef} from 'react'
import { FinanceTableApi } from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { NumericInput, SectionHeader } from '.'
import { ModuleComment } from '../components/'

type FinanceTableProps = {
   readonly moduleId: number;
   readonly bridgeId?: number;
   readonly subId: number;
   readonly descriptionTitle:string;
   readonly valueTitle:string;
   readonly sumTitle:string;
   readonly infoText?:string;
   readonly showHeader?:boolean;
   readonly header?:string;
   readonly order?:number;
   readonly displayIcons?:boolean;
   readonly hideInput?:boolean;
   readonly showComment?:boolean;
   readonly allowMultiple?:boolean;
   readonly onValueChangeCb?: (value: number) => void;
   readonly onValueDeleteCb?: () => void;
   readonly onTableChangedCb?: (id: number, heading:string) => void;
   readonly onTableAddedCb?: (id: number) => void;
   readonly onTableDeletedCb?: (id: number) => void;
 };

const keyPressTimeout = 1000;
let inputTimer = null;
function FinanceTable(props:FinanceTableProps) {


   const [financeTable, setFinanceTable] = useState({
      financeTableId:0,
      subId:0,
      heading:'',
      bridgeId:0
   });
   const [financeRows, setFinanceRows] = useState([]);
   const [showHeader, setShowHeader] = useState(false);
   const [dynamicBridgeId, setdynamicBridgeId] = useState(0);

   const [sum, setSum] = useState(0);
   useEffect(() => {
      if (props.showHeader != null) {
         setShowHeader(props.showHeader);
      }

      var bridgeId = 0;
      if(props.bridgeId != null){
         bridgeId = props.bridgeId
      }

      //Fetch comments for this module
      FinanceTableApi.get(props.moduleId, props.subId, bridgeId)
      .then(         
         (response) => { 
            setFinanceTable(response.data);
            setFinanceRows(checkIfRowsShouldBeAdded(response.data.rows, response.data.financeTableId));
            calculateSum(response.data.rows);
         },
         (error) => {
            console.log(error);
         })

   }, [props.moduleId, props.subId]);


   const checkIfRowsShouldBeAdded = (rows, financeTableId) => {
      var add = true;
      if (rows.length > 0) {
         var last = rows[rows.length-1];
         if ((last.description == null || last.description == '') &&  (last.value == null || last.value == 0)) {
            add = false;
         }
      }
      if (add)
         return addBlankRow(rows, financeTableId);
      else 
         return rows;
   }

   const calculateSum = (rows) => {
      var res=0;
      rows.map((row, index) => {
         res = res + row.value;
      })
      if (res != sum) {
         setSum(res);
         if (props.onValueChangeCb != null)
            props.onValueChangeCb(res);
      }
   }

   const addBlankRow = (rows, financeTableId) => {
      var blank = {
         financeTableRowId:0,
         financeTableId:financeTableId,
         description:'',
         value:0,
      }
      rows.push(blank);
      return rows;
   }

   const handleRowChange = (e, rowId) => {
         const { name, value } = e.target;
        
        
         setFinanceRows(
            financeRows.map(item => 
               item.financeTableRowId === rowId 
               ? {...item, [name] : value} 
               : item 
         ));
         if (inputTimer !== null){
            clearTimeout(inputTimer);
         }
         inputTimer = setTimeout(() => {
            var row = financeRows.find(x=> x.financeTableRowId === rowId);
            row[name] = value;
            updateRow(row);
         }, keyPressTimeout);
      
   }

   const handleRowBlur = (e, rowId) => {
      const { name, value } = e.target;
      if(value != ''){
         clearTimeout(inputTimer);
         setFinanceRows(
            financeRows.map(item => 
               item.financeTableRowId === rowId 
               ? {...item, [name] : value} 
               : item 
         ));
         var row = financeRows.find(x=> x.financeTableRowId === rowId);
         row[name] = value;
         updateRow(row);
        }
   }

   const onRowChange = (value, id) => {
      var row = financeRows.find(x=> x.financeTableRowId === id);
      row.value = value;
      updateRow(row);
   }

   const handleRowDelete = (rowId) => {
      if (rowId > 0) {
         FinanceTableApi.deleteRow(rowId)
         .then(
            (response) => { 
               setFinanceRows(financeRows.filter(row =>row.financeTableRowId !== rowId));
               calculateSum(financeRows.filter(row =>row.financeTableRowId !== rowId));
               if (props.onValueDeleteCb != null)
                  props.onValueDeleteCb();
            },
            (error) => {
               console.log(error);
            })
      }
   }

   const updateRow = (row) => {
      var data = {
         financeTableRowId:row.financeTableRowId,
         financeTableId:financeTable.financeTableId,
         description:row.description,
         value:row.value??0
      }
      FinanceTableApi.updateRow(data)
      .then(
         (response) => { 
            var newArray = financeRows.map(x => {
               if(x.financeTableRowId !== row.financeTableRowId) return x
                  return response.data
            });
            setFinanceRows(checkIfRowsShouldBeAdded(newArray, financeTable.financeTableId))
            calculateSum(newArray);
         },
         (error) => {
            console.log(error);
         })  
   }


   const onNameBlur = (e) => {
      const { name, value } = e.target;
      
      if (inputTimer !== null){
         clearTimeout(inputTimer);
      }

      if (financeTable.heading == value) {
         setFinanceTable((financeTable) => ({...financeTable,heading:value}));
         updateFinanceTable(value)
      }
   }

   const onNameChange = (e) => {
      const { name, value } = e.target;
      setFinanceTable((financeTable) => ({...financeTable,heading:value}));

      if (inputTimer !== null){
         clearTimeout(inputTimer);
       }
       inputTimer = setTimeout(() => {
         updateFinanceTable(value)
       }, keyPressTimeout);  
   }


   const updateFinanceTable = (heading) => {
      var data = {
         financeTableId:financeTable.financeTableId,
         heading:heading
      }
      FinanceTableApi.update(data)
      .then(
         (response) => { 
            props.onTableChangedCb(financeTable.financeTableId, heading)
         },
         (error) => {
            console.log(error);
         })  
   }

   const onBuildingAdd = () => {
      props.onTableAddedCb(financeTable.financeTableId)
   }
   const onBuildingDelete = () => {
      props.onTableDeletedCb(financeTable.financeTableId)
   }


   let inputHtml = (<>
      <input className="form-control " name="building" placeholder="Navn på bygning" type="text" key={'finance-table-header-' + financeTable.financeTableId} value={financeTable.heading} onChange={onNameChange} onBlur={onNameBlur}/>
    </>
    )

    let menuHtml = (<>
      <FontAwesomeIcon icon={faTrashAlt} title="Slett bygning" onClick={onBuildingDelete}/>
      <FontAwesomeIcon icon={faPlus}  title="Legg til bygning" onClick={onBuildingAdd}/>
</>);

   var rowsHtml = null;
   if (financeRows) {
      rowsHtml = financeRows.map((row, index) => {
         return <>
            <div className="module-row" key={"finance-table-" + row.moduleId + "-" + row.subId + "-" + row.bridgeId + "-" + row.financeTableRowId + "-" + index}>
                  <div className="flex-4">
                     <input name='description' key={"finance-table-description-" + index} className="form-control module-row-input" value={row.description} onChange={(e) =>handleRowChange(e,row.financeTableRowId)} onBlur={(e) =>handleRowBlur(e,row.financeTableRowId)}/>
                  </div>
                  <div className="flex-1">
                     <div className='module-row-input-with-suffix'>
                        <i>,-</i>
                        <NumericInput id={row.financeTableRowId} value={row.value} class={'align-right'} placeholder={'0'}  onValueChangeCb={onRowChange}></NumericInput>
                     </div>


                  </div>
                  <div className='delete-icon' onClick={() => handleRowDelete(row.financeTableRowId)}><FontAwesomeIcon icon={faTimes} className="tg-icon" title="Slett" /></div>

            </div>
         </>
      });
   }
  

   var contentHtml = <>
         <div className="module-row" key={"finance-table-header"}>
            <div className="flex-4 module-row-title-big bold">{props.descriptionTitle}</div>
            <div className="flex-1 module-row-title-big bold">{props.valueTitle}</div>
            <div className='delete-icon'></div>
         </div>
         {rowsHtml}

         <div className="module-row" key={"finance-table-sum"}>
            <div className="flex-4 module-row-title-big align-right bold"><span className='padding-right-20'>{props.sumTitle}</span></div>
            <div className="flex-1 module-row-title">
               <div className='module-row-input-with-suffix'>
                  <i>,-</i>
                     <NumericInput disabled={true} value={sum} placeholder={'0'} class={'align-right bold-important'} ></NumericInput>
               </div>   
            </div>
            <div className='delete-icon'></div>
         </div>   
         <div className='align-right infoText'>{props.infoText}</div>
   </>


   return <>  
      {(showHeader) ?
         <>
            <div className='module-section' key={'finance-table-' + financeTable.financeTableId}>
               <SectionHeader title={props.header} input={props.hideInput ? null : inputHtml} menu={props.allowMultiple ? menuHtml : null }></SectionHeader> 
               <div className='content-frame'>
               {contentHtml}
               </div>
            </div>

            {
               props.showComment ? 
               <><ModuleComment moduleId={props.moduleId} multiComment={true} readOnly={false} phraseKey='calculation.comments'></ModuleComment></>
               :
               <></>
            }
         </>      
      :      
         <>
         {contentHtml}
         </>
      }





   </>

}
  
export default FinanceTable;