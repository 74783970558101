import React, {useState, useEffect} from 'react'
import {AutoTextBox, UploadImage} from '..'


export interface ImageWithSubTitleProps {
   readonly picture: any,
   readonly showTitle: boolean,
   readonly class: string,
   readonly allowEdit: boolean,
   readonly readOnly: boolean,
   readonly updatePictureCb?: (id:number, url:string, subTitle: string, mainTitle: string) =>  Promise<any>;
 }

const keyPressTimer = 1000;
let inputTimer = null;
function ImageWithSubTitle(props:ImageWithSubTitleProps) {

   const [id, setId] = useState(0);
   const [url, setUrl] = useState(null);
   const [subtitle, setSubtitle] = useState('');
   const [maintitle, setMainTitle] = useState('');

   const [initialized, setInitialized] = useState(false);
   useEffect(() => {
      if (props.picture != null) {
         setId(props.picture.id);
         if (!initialized) {
            setUrl(props.picture.url);
            setSubtitle(props.picture.subTitle);
            setMainTitle(props.picture.mainTitle);
            setInitialized(true);
         }
      }
   }, [props])
   

   const onBlur = (e) => {
      const { name, value } = e.target;
      setMainTitle(value);

      if (inputTimer !== null){
         clearTimeout(inputTimer);
         props.updatePictureCb(id, url, subtitle, value); 
      }
   }

   const onValueChange = (e) => {
      const { name, value } = e.target;
      setMainTitle(value);
    
      if (inputTimer !== null){
         clearTimeout(inputTimer);
       }
       inputTimer = setTimeout(() => {
         props.updatePictureCb(id, url, subtitle, value); 
       }, keyPressTimer);  
   }
   
   const onTextChange = (value) => {
      setSubtitle(value);
      props.updatePictureCb(id, url, value, maintitle); 
   }
   
   const onUrlChanged = (value) => {
      setUrl(value);
      props.updatePictureCb(id, value, subtitle, maintitle);  
   }

   let inputHtml = (
      <div className="description-input" >
         <input className="description-input" name="titel"  placeholder="Valgfri overskrift" type="text" value={maintitle}
         onChange={onValueChange} onBlur={onBlur}/>
      </div>
   );


   return ( 
   <> 
      {props.showTitle &&
      <div>
         <div className='category-header-module margin-bottom-10'>{inputHtml}</div>
      </div>
      }
      <div className={props.class}>
         <UploadImage pictureUrl={url} readOnly={!props.allowEdit} onUrlChangedCb={onUrlChanged}></UploadImage>
      </div>
      <div className='mb-3'>
         <AutoTextBox text={subtitle} onValueChangeCb={onTextChange}></AutoTextBox>
      </div>
   </>
  )
           
                      
}
  
export default ImageWithSubTitle;