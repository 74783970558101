import React, {useState, useEffect, useRef} from 'react'
import { UploadDialog } from '../Dialogs'

type UploadImageProps = {
   readonly pictureUrl: string;
   readonly width?: number;
   readonly height?: number;
   readonly maxHeight?: number;
   readonly readOnly?: boolean;
   readonly onUrlChangedCb?: (value: string) => void;
 };


function UploadImage(props:UploadImageProps) {

   const [pictureUrl, setPictureUrl] = useState('');
   const [openUploadDialog, setOpenUploadDialog] = useState(false);

   useEffect(() => {
      setPictureUrl(props.pictureUrl);
   }, [props]);



   const handleChangePicture = function() {
      if (!props.readOnly) {
         setOpenUploadDialog(true);
      }
    }

   const handleImageUrlUpdated = function(newUrl) {
      //Todo delete
     setOpenUploadDialog(false);
     setPictureUrl(newUrl);
     
     props.onUrlChangedCb(newUrl);
   }
 
   const handleImageCancel = function() {
     setOpenUploadDialog(false);
   }


   const getPicture = function(imageUrl) {
      if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
         return (
            <div className="upload-image-frame"  onClick={handleChangePicture} title="Klikk for å endre bilde">
               <img className="upload-image-img" src={"/upload/" + imageUrl} />
            </div>
         )
      }
      else {
        return ( 
            <div className="upload-image-frame-no-image"  onClick={handleChangePicture} title="Klikk for å endre bilde">
               <div className="upload-image-no-image"><div>Last opp bilde</div></div>
            </div>
         );
      }
    }



  
   return <>

      {getPicture(pictureUrl)}    

      <UploadDialog
               open={openUploadDialog}
               handleImageUrlUpdatedCb={handleImageUrlUpdated}
               handleCancelCb={handleImageCancel}
               url={pictureUrl}
               />
   </>
}
  
export default UploadImage;