import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BkButton } from '..'
import { ContactButton } from '..'
import RoomRow from './RoomRow';
import { PhraseApi } from '../../api';
import { ConfirmationDialog } from '.'


export interface SelectRoomsDialogProps {
   open: boolean;
   phraseKey:string;
   text:string;
   alphabetical: boolean;
   handleCancelCb: () => void;
   handleSaveCb: (text: string) => void;
 }

const SelectRoomsDialog = (props: SelectRoomsDialogProps) => {
   const [openDialog, setOpenDialog] = useState(false);
   const [phrases, setPhrases] = useState([]);
   const [selectedPhrases, setSelectedPhrases] = useState([]);
   const [focusPhraseId, setFocusPhraseId] = useState(0);
   const [newPhraseText, setNewPhraseText] = useState("")
   const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
   const [idToBeDeleted, setIdToBeDeleted] = useState(0);

   useEffect(() => {
      setOpenDialog(props.open);
      setSelectedPhrases([]);
      if (props.open) {
         
         PhraseApi.getPhrases(props.phraseKey, props.alphabetical)
         .then(
           (response) => {
              setPhrases(response.data);
           },
           (error) => {
             //    
             console.log(error);
           });
      }
   }, [props.open]);

   const handleClose = () => {
      props.handleCancelCb();
   };

   const handleConfirmDialogClose = (id) =>{
      setDisplayConfirmDialog(false);
      if (id > 0) {
         PhraseApi.deletePhrase(id)
         .then(
           (response) => {
              setPhrases(phrases.filter(p=>p.phraseId != id));
              setIdToBeDeleted(0);
           },
           (error) => {
             //    
             console.log(error);
           });
      }
   }

   const handleDelete = (id) => {
      setIdToBeDeleted(id);
      setDisplayConfirmDialog(true);
   };

   const handleSave = () => {
      var text='';
      var len = selectedPhrases.length;
      selectedPhrases.map((phrase, index) => {
         if (phrase.selected === true) {
            if (index == 0 ) {
               if (props.text === '') {
                  text = phrase.text; 
               }
               else {
                  text = phrase.text; 
               }
            }
            else if (index == len-1) {
               text = text + ", " + phrase.text;
            }
            else {
               text = text + ", " + phrase.text;
            }
         }
         return phrase;
       })
      props.handleSaveCb(text);
   }

   const handleRoomSelected = (id, selected) => {
      if (id > 0) {
         var thePhrase = null;
         phrases.map((phrase, index) => {
            if (phrase.phraseId === id) {
               phrase.selected=selected;
               thePhrase = phrase;
            }
            return phrase;
          })

          if (selected) {
            setSelectedPhrases(selectedPhrases => [...selectedPhrases,thePhrase])
          }
          else {
            setSelectedPhrases(selectedPhrases.filter(p=>p.phraseId != id));
          }
      }
   };

   const handleAdd = () => {
      var newPhrase = {
         phraseId:0,
         userId:0,
         key:props.phraseKey,
         text: "",
         order:0
      }
      const newArray = [...phrases];
      newArray.splice(0, 0, newPhrase);
      setPhrases(newArray);
      setFocusPhraseId(0);
      var phrase = newArray.find(phrase =>phrase.phraseId == 0);
      if (phrase != null) {
         phrase.text = newPhraseText;
         addPhrase(phrase, newArray);
      }
   }

   const addPhrase = (phrase, array) => {
      PhraseApi.addPhrase(phrase)
         .then(
           (response) => {
            var copy = array.map((phrase, index) => {
               if (phrase.phraseId === 0) {
                  if (focusPhraseId == 0) {
                     setFocusPhraseId(response.data.phraseId);
                  }
                  return response.data;
               }
               return phrase;
            })
            setPhrases(copy);
            setNewPhraseText('')
           },
           (error) => {
             //    
             console.log(error);
           });
   }

   const handleNewPhraseChange = function(e) { 
      const { value } = e.target;
      setNewPhraseText(value)
   }

   const handleUpdated= (phraseId, text) => {
      if (phraseId == 0) {
         //New (post)
         var phrase = phrases.find(phrase =>phrase.phraseId == 0);
         if (phrase != null) {
            phrase.text = text;
            addPhrase(phrase, phrases);
         }
      }
      else {
         // existing => put
         var phrase = phrases.find(phrase =>phrase.phraseId == phraseId);
         if (phrase != null) {
            phrase.text = text;
            updatePhrase(phrase);
         }
      }
   }

   const updatePhrase = (phrase) => {
      PhraseApi.updatePhrase(phrase)
         .then(
           (response) => {
            var copy = phrases.map((phrase, index) => {
               if (phrase.phraseId === 0) {
                  if (focusPhraseId == 0) {
                     setFocusPhraseId(response.data.phraseId);
                  }
                  return response.data;
               }
               return phrase;
            })
            setPhrases(copy);
           },
           (error) => {
             //    
             console.log(error);
           });
   }

   
   const getTitle = () => {
         return 'Velg Rom'
   }

   let phrasesHtml = null;
   phrasesHtml = phrases.map((phrase) =>  
   {  
      return (  
         <>
            <RoomRow  
            roomId={phrase.phraseId} 
            room={phrase.text} 
            handleSelectedCb={handleRoomSelected}
            handleUpdatedCb={handleUpdated}
            handleDeleteCb={handleDelete} 
            editMode={phrase.phraseId == focusPhraseId}
            hasFocus= {phrase.phraseId == focusPhraseId}
            />
         </>
      )         
   });

   return (
      <>
      
      <Dialog open={openDialog} fullWidth={true} maxWidth = {'sm'} onClose={handleClose} aria-labelledby="form-dialog-title" keepMounted={false} >
      <DialogTitle id="draggable-dialog-title" className="dialog-header">
            <div className='upload-header-row'>
            <div className='upload-header'>{getTitle()}</div>
            </div>
         </DialogTitle>
            <div className='phrase-input-line'>
            Nytt rom:
            <input className="form-control" value={newPhraseText} onChange={(e)=>handleNewPhraseChange(e)} name="room" placeholder="Navn på rommet" type="text"/>
            <BkButton  caption='Legg til i liste' onClickHandler={handleAdd}/> 
            </div>
         <DialogContent>
            <div className="phrase-text-container mt-3"> 
             {phrasesHtml}
            </div>
         </DialogContent>
         <DialogActions  className="dialog-buttons">
            <ContactButton caption='Avbryt' onClickHandler={handleClose}/>
            <BkButton caption='Legg til' onClickHandler={handleSave}/>
         </DialogActions>
      </Dialog>
      
      <ConfirmationDialog
          title="Slette?"
          text="Er du sikker på at du vil slette frasen"
          open={displayConfirmDialog}
          id={idToBeDeleted}
          onClose={handleConfirmDialogClose}
      />


      </>

   )
}
 

export default React.memo(SelectRoomsDialog);