import React, {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators} from '../../store/auth';
import { ProjectApi, ProjectsApi, CountyApi } from '../../api';
import { useHistory } from 'react-router-dom'
import {ProjectRow, EditProjectDialog} from './child-components'
import SearchIndicator from '../../components/SearchIndicator'
import {isNullOrEmpty } from '../../utils'
import { BkButton, Spinner,BkButtonSmall } from '../../components'
import ReportFilter from './child-components/ReportFilter';
import OwnerFilter from './child-components/OwnerFilter';

type ProjectProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly role: number;
   readonly userName: string;
   readonly userId: string;
};

const defaultTake = 40;
const keyPressTimer = 500;
let searchTimer = null;

const Projects: React.FC<ProjectProps> = ({ 
   isAuthenticated, 
   userName,
   role,
   logoutUser
}) => {
   const [isLoading, _setIsLoading] = useState(true);
   const [isMount, setIsMount] = useState(true);
   const [projects,setProjects]= useState([]);
   const [sharedUsers,setSharedUsers]= useState([]);
   const [usersFilter,setUsersFilter]= useState([]);
   const [loggedUserId,setLoggedUserId]= useState(0);
   const [searchText,setSearchText] = useState('');
   const [openNew, setOpenNew] = React.useState(false);
   const [addressData, setAddressData] = useState({
      projectId:0,
      address: '',
      zipCode: '',
      city: '',
      gnr:'',
      bnr: '',
      fnr: '',
      snr: '',
      lnr: '',
      andelsnr: '',
      countyId: 0,
   });
   const [sortField, setSortField] = useState("ModifiedDate");
   const [ascending, setAscending] = useState(false);
 
   const [counties, setCounties] =useState([]);
   const [reportTypes, setReportTypes] =useState([]);
   const [selectedReportType, setSelectedReportType] = useState(0);
   const [searchModel,_setSearchModel] = useState({
      SearchText:'',
      index:0,
      take:defaultTake,
      sortField:"ModifiedDate",
      sortAscending:false,
      reportTypeId:0,
      userId:-1,
   })

   const searchModelRef = React.useRef(searchModel);
   const setSearchModel = (data) => {
     _setSearchModel(data);
   };

   const isLoadingRef = React.useRef(isLoading);
   const setIsLoading = data => {
      isLoadingRef.current = data;
     _setIsLoading(data);
   };

   const history = useHistory();
   
   if(!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   useEffect(() => {
      setSearchModel(searchModel=> ({...searchModel,take:defaultTake}));
      setSearchText('');
      fetchCounties();
      // fetchCustomerSharing()    
      fetchReportTypes();
      /*const handleScroll = e => {
         if (!isLoadingRef.current && (window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight) { 
            var index = searchModelRef.current.index + searchModelRef.current.take;
            setSearchModel(searchModel=> ({...searchModel,take:defaultTake, index:index}));
         }
      }*/

      /*document.addEventListener("scroll", handleScroll)
      return () => document.removeEventListener("scroll", handleScroll)*/

   }, []);
   
   useEffect(() => {
      searchModelRef.current = searchModel;
      // First to skip the initialising phase. 
      // because of security reasons we have to check user role before fetching
      if(isMount){
         setIsMount(false);
         return;
      }
      // Fetch data based on the changes in the search model
      fetchData(); 
      fetchReportTypes();
   }, [searchModel])


   const fetchData = async () => {
      setIsLoading(true);
      ProjectsApi.getProjects(searchModel)
      .then(
         (response) => {
            if (searchModel.index > 0) {
               setProjects([...projects, ...response.data.projects]);  
            }
            else {
               setProjects(response.data.projects); 
               setSharedUsers(response.data.sharedUsers);
               setUsersFilter(response.data.usersFilter);
               setLoggedUserId(response.data.loggedUserId);
            }
            setIsLoading(false);
         },
         (error) => {
            console.log(error);
            setIsLoading(false);
         });
   }
   
   // const fetchCustomerSharing = async () => {
   //    console.log(userId)
   //    ProjectsApi.getCustomerSharing(userId)
   //    .then(
   //       (response) => {
   //          console.log(response.data)
   //          setSharedCustomers(response.data);
   //       },
   //       (error) => {
   //          console.log(error);
   //       });
   // }


   const fetchCounties = async () => {
      CountyApi.getCounties()
      .then(
         (response) => {
            setCounties(response.data);
         },
         (error) => {
            console.log(error);
         });
   }

   const fetchReportTypes = async () => {
      ProjectsApi.getReportTypes(searchModel.userId)
      .then(
         (response) => {
            setReportTypes(response.data);  
         },
         (error) => {
            console.log(error);
         });
   }

   const handleProjectSearch = function(e) {
      var searchstring = e.target.value.toLowerCase();
      setSearchText(searchstring);
   
      if (searchTimer !== null){
         clearTimeout(searchTimer);
       }
       searchTimer = setTimeout(() => {
         doProjectSearch(searchstring)
       }, keyPressTimer);

   }

   const doProjectSearch = function(searchstring) {
      if (searchstring.length > 2) {
         setSearchModel({...searchModel, searchText:searchstring, index:0});
      }
      else  {
         setSearchModel({...searchModel, searchText:"", index:0});
      }
   }


   const handleSort= (field) => {
      var direction = ascending;
      if (field == sortField) {
         direction = !direction;
      }
      else {
         direction = false;
      }
      setSearchModel({...searchModel, sortAscending:direction, sortField:field, index:0});

      setAscending(direction);
      setSortField(field);
   }



   const handleProjectDelete = function(id) {
      ProjectApi.deleteProject(id)
      .then(
         (response) => {
         setProjects(projects.filter(p =>p.projectId !== id));
         },
         (error) => {
         console.log(error);
         });
   }

  
   const handleProjectCopy = function(id) {
      var data = {
         projectId :id,
         calculationId:id
      }
      setIsLoading(true);
      ProjectApi.copyProject(data)
      .then(
         (response) => {
         //setProjects(projects.filter(p =>p.projectId !== id));
         setIsLoading(false);
         fetchData();
         },
         (error) => {
            console.log(error);
            setIsLoading(false);
         });
   }

   const handleClickOpenNew = () => {
      // reset project data.
      setAddressData({...addressData, projectId:0, address: '',
         zipCode: '',
         city: '',
         gnr:'',
         bnr: '',
         fnr: '',
         snr: '',
         lnr: '',
         andelsnr: '',
         countyId: 0,
      });

      setOpenNew(true);
   };

   const handleCloseNew = (address, userId) => {
      var data = {
         userId:userId,
         address: address
      };
      if (address != null) {
         ProjectApi.addProject(data)
         .then(
         (response) => {
            fetchData();
            //project.id = response.data;
            //setProjects(dataProjects => [...dataProjects, project]);
         },
         (error) => {
            console.log(error);
         }
         );
      }
      setOpenNew(false);
   };


   const reportTypeSelected = (reportTypeId) => {
      setSelectedReportType(reportTypeId);
      setSearchModel({...searchModel, reportTypeId:reportTypeId, index:0});
   }

   const ownerFilterSelected = (userId) => {
      setSelectedReportType(0);
      setSearchModel({...searchModel, userId:userId, reportTypeId:0, index:0});
   }

  let projectRows = null;
  if (projects.length > 0){
    projectRows = projects.map((project, index) =>   
          <ProjectRow loginName={userName} project={project} index={index} onCopyCb={handleProjectCopy} onDeleteCb={handleProjectDelete}></ProjectRow>
    );
  }

  const handleScroll = function (e) {
   const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 2;
   //console.log(e.target.scrollHeight + ", " + e.target.scrollTop + ", " + e.target.clientHeight);
   if (bottom && !isLoading) {
      var index = searchModelRef.current.index + searchModelRef.current.take;
      setSearchModel(searchModel=> ({...searchModel,take:defaultTake, index:index}));

   }

}


const showOwner = function (){
   if (projects.filter((p) => p.userName != userName).length != 0){
      return 'Bygningssakkyndig'
   }
}

  return (
    <div className="main-container container">

      <div className="title-row">

            <div className='title-btn-group'>
               <div className="bk-row-header bk-row-header-title ">Prosjektoversikt</div>
               <BkButton caption='Legg til prosjekt' onClickHandler={handleClickOpenNew}/>
            </div>

            <div className='filter-search-group'>
               <div className='filter'>
                  {sharedUsers != null && sharedUsers != undefined && sharedUsers.length != 0 ? 
                  <OwnerFilter loggedUserId={loggedUserId} owners={usersFilter} onOwnerFilterCb={ownerFilterSelected}></OwnerFilter> 
                  : ''}
                  <ReportFilter filters={reportTypes} selectedFilter={selectedReportType} reportTypeSelectedCb={reportTypeSelected}></ReportFilter>
               </div> 
               <input className={['form-control', ''].join(' ')} placeholder="Søk" name="ProsjektSøk" type="text" value={searchText} onChange={handleProjectSearch} />
            </div> 

      </div>
   
      <div className='main-table'>
         <div className="main-table-header-row main-table-grid">
            <div className=""></div>
            <div className="">Status</div>
            <div className="search-cursor" onClick={() =>handleSort("CreatedDate")}>Opprettet <SearchIndicator visible={sortField == "CreatedDate"} ascending={ascending}></SearchIndicator></div>
            <div className="search-cursor" onClick={() =>handleSort("ModifiedDate")}>Endret <SearchIndicator visible={sortField == "ModifiedDate"} ascending={ascending}></SearchIndicator></div>
            <div className="search-cursor bk-project-row-align-left" onClick={() =>handleSort("Address")}>Adresse <SearchIndicator visible={sortField == "Address"} ascending={ascending}></SearchIndicator></div>
            <div className="">Gnr</div>
            <div className="">Bnr</div>
            <div className="">Fnr</div>
            <div className="">Snr</div>
            <div className="">Lnr</div>
            <div className="">Aksje / Anr</div>
            <div className="">{showOwner()}</div>
            <div className="">
               <div></div>
            </div>
         </div>
         <div className="main-table-data-container" onScroll={handleScroll}>
            {projectRows}
         </div>
      </div>

      <EditProjectDialog
        title="Legg til prosjekt"
        open={openNew}
        onClose={handleCloseNew}
        address={addressData}
        userId={0}
        counties={counties}
        sharedUsers={sharedUsers}
      />

      <Spinner isLoading={isLoading} />
    </div>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(Projects);
