import React, {useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faBars } from '@fortawesome/free-solid-svg-icons'
import { PhraseDialog } from './Dialogs'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { PhraseApi } from '../api'
import { toXmlSafeText } from '../utils';


type AutoTextBoxProps = {
   readonly text: string;
   readonly defaultRows?: number;
   readonly lineHeight?: number;
   readonly phraseRegisterKey?:string;
   readonly class?:string;
   readonly id?:string;
   readonly focus?:boolean;
   readonly readOnly?:boolean;
   readonly addPadding?:boolean;
   readonly smallIcon?:boolean;
   readonly onValueChangeCb?: (value: string) => void;
   readonly onSelectedChangeCb?: (value: string) => void;
 };

const keyPressTimer = 1000;
let inputTimer = null;

function AutoTextBox(props:AutoTextBoxProps) {

   const [rows, setRows] = useState(1);
   const [text, setText] = useState('');
   const [selectedText, setSelectedText] = useState('');
   const [lineHeight, setLineHeight] = useState(18);
   const [openPhraseDialog, setOpenPhraseDialog] = useState(false);
   const textBoxRef = useRef(null);
   const [key, setKey] = useState('');
   const [id, setId] = useState('auto');
   const [readOnly, setReadOnly] = useState(false);

   var init = 0;

   useEffect(() => {
      setKey(props.phraseRegisterKey);
      if (props.defaultRows != null) {
         setRows(props.defaultRows);
      }
      if (props.lineHeight != null) {
         setLineHeight(props.lineHeight);
      }
      setText(props.text)
      if (props.id) {
         setId(props.id);
      }

      if (props.readOnly != null){
         setReadOnly(props.readOnly);
      }

   }, [props]);


   useEffect(() => {
      if (!init) {
         const previousRows = textBoxRef.current.rows;
         textBoxRef.current.rows = props.defaultRows;

         const currentRows = ~~(textBoxRef.current.scrollHeight / lineHeight);
         if (currentRows === previousRows) {
            textBoxRef.current.rows = currentRows;
         }
         setRows(currentRows);
         init=1;
      }
      if (props.focus) {
         textBoxRef.current.focus();
      }
   });

   const onBlur = () => {
      if (inputTimer !== null){
         clearTimeout(inputTimer);
         props.onValueChangeCb(text);
      }
   }

   const onValueChange = (e) => {
      const minRows = props.defaultRows;
      const { name, value } = e.target;
      setText(toXmlSafeText(value));

      const previousRows = e.target.rows;
  	   e.target.rows = minRows; // reset number of rows in textarea 
		
		const currentRows = ~~(e.target.scrollHeight / lineHeight);

      if (currentRows === previousRows) {
         e.target.rows = currentRows;
      }
		
		setRows(currentRows);
      
      if (inputTimer !== null){
         clearTimeout(inputTimer);
       }
       inputTimer = setTimeout(() => {
         updateValue(toXmlSafeText(value));
       }, keyPressTimer);  
   }

   const updateValue = (value) => {
      props.onValueChangeCb(value);
   }

   const openPhraseregister = () => {
      let cursorStart = textBoxRef.current.selectionStart;
      let cursorEnd = textBoxRef.current.selectionEnd;
      if (cursorEnd > cursorStart) {
         setSelectedText(text.substring(cursorStart,cursorEnd));
      }
      else {
         setSelectedText(text);
      }
      setOpenPhraseDialog(true);
   }


   const handlePhraseSave = (value) => {
      setOpenPhraseDialog(false);
      if (value == '') {
         return;
      }
      if (text== null || text==undefined || text=='') {
         setText(value);
         props.onValueChangeCb(value);
      }
      else {
         setText(text + '\r\n' + value);
         props.onValueChangeCb(text + '\r\n' + value);
      }
   }


   const handlePhraseCancel = () => {
      setOpenPhraseDialog(false);
   }


   const savePhrase = () => {
      if (text== null || text==undefined || text=='') {
         //Nothing to save
         return;
      }

      var data = {
         phraseId:0,
         userId:0,
         key:props.phraseRegisterKey,
         text:text
      };
      PhraseApi.addPhrase(data)
      .then(
        (response) => {
           //ok
        },
        (error) => {
          //    
          console.log(error);
        });
   }


   const handleMouseUp = () => {
      if (props.onSelectedChangeCb != null) {
         let cursorStart = textBoxRef.current.selectionStart;
         let cursorEnd = textBoxRef.current.selectionEnd;
         var selected = '';
         if (cursorEnd > cursorStart) {
            selected = text.substring(cursorStart,cursorEnd)
         }
         
         if (selected != selectedText) {
            setSelectedText(selected);
            props.onSelectedChangeCb(selected);
         }
      }
     
   }

   function ActionMenu(props) {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
    
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };

      const saveAndClose = () => {
         setAnchorEl(null);
         savePhrase();
      }
      
      return (
         <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
            <FontAwesomeIcon icon={faClipboardList}  onClick={handleClick}/>
            <Menu
                  elevation={0}
                  transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                  }}
               id="fade-menu"
               anchorEl={anchorEl}
               keepMounted
               open={open}
               onClose={handleClose}
               TransitionComponent={Fade}
               className="navbar-dropdown-menu"
            >
               <MenuItem onClick={saveAndClose}>Legg til i fraseregister</MenuItem>
               <MenuItem onClick={openPhraseregister}>Ã…pne fraseregister</MenuItem>
            </Menu>
         </div>);
  
   }
  
   return <>
      <div className="auto-text-box-frame" key={id}>
         <div className="text-box">
            <textarea ref={textBoxRef} readOnly={readOnly} className={"form-control " + props.class} rows={rows} value={text} onChange={(e) => onValueChange(e)} onBlur={onBlur} onMouseUp={handleMouseUp}/>
         </div>
         {(props.smallIcon) &&
            <> 
               <div className="add-icon" >
                  {/* <ActionMenu /> */}
                  <FontAwesomeIcon icon={faClipboardList}  onClick={openPhraseregister}/>
               </div>
            </>               
         }
         {(!props.smallIcon && props.phraseRegisterKey != null && props.phraseRegisterKey!= '' && !readOnly) &&
            <> 
               <div className="phrase-icon" >
                  {/* <ActionMenu /> */}
                  <FontAwesomeIcon icon={faClipboardList}  onClick={openPhraseregister}/>
               </div>
            </>               
         }
         {(props.addPadding) && 
            <>
            <div className="phrase-icon" ></div>
            </>
         }
      </div>

      <PhraseDialog
         open={openPhraseDialog}
         phraseKey={key}
         text={selectedText}
         handleSaveCb = {handlePhraseSave}
         handleCancelCb = {handlePhraseCancel}
      />

     
   </>
}
  
export default AutoTextBox;